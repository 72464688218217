import React from 'react'
import { useBreakpoint } from 'react-laag'
import { ParallaxHover } from 'react-parallax-hover'
import Link from '../Link/Link'

import './styles.scss'

interface GuideLinkProps {
  link: string
  title: string
  subtitle: string
  exampleImage: string
  accentColor: string
}

const GuideLink: React.FC<GuideLinkProps> = ({
  link,
  title,
  subtitle,
  exampleImage,
  accentColor
}) => {
   const isMobile = useBreakpoint(480)
   const isTablet = useBreakpoint(768)

  const linkWidth =
    isMobile
      ? 320
      : isTablet
        ? 350
        : 294

  return (
    <Link to={link} className="guide-link">
      <ParallaxHover
        width={linkWidth}
        height={270}
        borderRadius={8}
        shadow={0}
        rotation={3}
        shine={2}
        scale={1}
      >
        <div className="guide-link__bg" />
        <div className="guide-link__accent-bg" style={{backgroundColor: accentColor}} />
        <div className="guide-link__example-card">
          <img src={exampleImage} alt={title} />
        </div>
        <div className="guide-link__content">
          <h6>{title}</h6>
          <p>{subtitle}</p>
        </div>
      </ParallaxHover>
    </Link>
  )
}

export default GuideLink
